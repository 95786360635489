import axios from "axios";
import moment from "moment-timezone";

interface IPatientInfo extends Partial<IData> {
  currentRoundPlan: string;
  currentMedDS: string;
}

export const getTokenAws = async () => {
  let token = "";
  try {
    const res = await axios.get(
      "https://rwwtjh3s6f.execute-api.ap-southeast-2.amazonaws.com/dev/auth-token"
    );
    if (res) {
      const result = JSON.parse(res.data.body);
      token = result.access_token;
    }
  } catch (err) {
    console.log(err);
  }
  return token;
};

export const getTokenAzure = async () => {
  let token = "";
  try {
    const res = await axios.get(
      `https://medbr.azurewebsites.net/api/requestJWT?code=${process.env.REACT_APP_AZURE_TOKEN}`
    );
    if (res) {
      const result = res.data;
      token = result.access_token;
    }
  } catch (err) {
    console.log(err);
  }
  return token;
};

// const planData = {
//   clinicalImpression: "Agree with primary and differential diagnosis",
//   treatmentPlan: "Agree with existing treatment plan",
//   investigations: "Agree with investigations done/planned",
//   referrals: "Agree with referrals done/planned",
// };

const getPatients = (
  token: string,
  batch: string,
  client: string,
  setLoading: (loading: boolean) => void,
  setPatients: (patients: Array<IData>) => void,
  setClients: (clients: Array<string>) => void,
  dispatch: (setReduxTempRound: void) => void,
  setReduxTempRound: (round: Round) => void,
  setReduxNoListRound: (round: Round) => void,
  setRoundMessage: (roundMessage: string) => void,
  setReduxTempPatients: (tempPatients: Array<IData>) => void,
  setAddPatients: (addPatients: boolean) => void,
  setReduxFilteredPatients: (tempPatients: Array<IData>) => void
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  axios
    .get(
      `https://br-apim.azure-api.net/medaws/getPatients?client=${client}&batch=${batch}`,
      config
    )
    .then((res) => {
      console.log("azure get selected round", res.data);
      setLoading(false);
      setAddPatients(true);
      const result = res.data.patients;
      if (result.length === 0) {
        setRoundMessage(
          "No patient list available for this round. Please use the add patient button to record patient information for this round"
        );
      }
      const newResult = [...result];
      setPatients(newResult);
      const localStoragePatients = window.localStorage.getItem(
        `${res.data.client} ${res.data.batch}`
      );
      if (!localStoragePatients) {
        console.log("reset storage");
        if (result.length > 0) {
          window.localStorage.setItem(
            `${res.data.client} ${res.data.batch}`,
            JSON.stringify(newResult)
          );
        }
        dispatch(setReduxTempPatients(newResult));
        dispatch(setReduxFilteredPatients(newResult));
      } else {
        dispatch(setReduxTempPatients(JSON.parse(localStoragePatients)));
        dispatch(setReduxFilteredPatients(JSON.parse(localStoragePatients)));
      }
      const batch = res.data.batch.split(" ").slice(1, 3);
      let time;
      if (batch[0].includes(":")) {
        time = batch[0];
      } else {
        time = `${Number(batch[0])}:00`;
      }
      setClients([
        `${
          res.data.client === "GoulburnValleyHealth"
            ? "Goulburn Valley"
            : res.data.client
        } - ${time + batch[1]}`,
      ]);
      const startTime = moment().format("YYYY-MM-DD[T]HH:mm:ss");
      const obj = Object.assign(
        {},
        {
          ...res.data,
          startTime: startTime,
        }
      );
      if (result.length > 0) {
        dispatch(setReduxTempRound(obj));
      } else {
        dispatch(setReduxNoListRound(obj));
      }
    })
    .catch((err) => {
      console.log("azure getPatients err", err);
      setLoading(false);
      setAddPatients(true);
      setRoundMessage(
        "No patient list available for this round. Please use the add patient button to record patient information for this round"
      );
    });
};

export const getSessions = (
  token: string,
  currentClient: string,
  setRoundMessage: (roundMessage: string) => void,
  setLoading: (loading: boolean) => void,
  setPatients: (patients: Array<IData>) => void,
  setClients: (clients: Array<string>) => void,
  dispatch: (setReduxTempRound: void) => void,
  setReduxTempRound: (round: Round) => void,
  setReduxNoListRound: (round: Round) => void,
  setReduxTempPatients: (tempPatients: Array<IData>) => void,
  setAddPatients: (addPatients: boolean) => void,
  setReduxFilteredPatients: (tempPatients: Array<IData>) => void
) => {
  setAddPatients(false);
  let utcDt = moment(moment()).utc().format("YYYY-MM-DD hh:mm:ss A");
  const today = moment
    .utc(utcDt, "YYYY-MM-DD hh:mm:ss A")
    .tz("Australia/Sydney")
    .format("YYYY-MM-DD");
  const tomorrow = moment(today, "YYYY-MM-DD")
    .add(1, "days")
    .format("YYYY-MM-DD");
  const config = { headers: { Authorization: `Bearer ${token}` } };
  axios
    .get(
      `https://br-apim.azure-api.net/medaws/getBRsessions?client=${currentClient}&dateRangeStart=${today}&dateRangeEnd=${tomorrow}`,
      config
    )
    .then((res) => {
      const sessions = res.data.sessions;
      if (sessions.length > 0) {
        // 15mins = 15mins in start time
        const nextDates = sessions.filter(function (session: Session) {
          utcDt = moment(moment()).utc().format("YYYY-MM-DD hh:mm:ss A");
          const nowSydney = moment
            .utc(utcDt, "YYYY-MM-DD hh:mm:ss A")
            .tz("Australia/Sydney")
            .format("DD/MM/YYYY hh:mm:ss A");
          // console.log('nowSydney',nowSydney)
          // 30mins = 15mins in start time + 15mins in 900000
          // const nowPlus =
          // moment(nowSydney, "DD/MM/YYYY hh:mm:ss A").valueOf() + 900000;

          const nowTime = moment(nowSydney, "DD/MM/YYYY hh:mm:ss A").valueOf();
          const newStartTime = moment(
            session.startTime?.toLocaleString()
          ).format("DD/MM/YYYY hh:mm:ss A");
          const sessionStartTime = moment(
            newStartTime,
            "DD/MM/YYYY hh:mm:ss A"
          ).valueOf();
          // console.log('newStartTime',newStartTime)
          return nowTime - sessionStartTime < 0;
        });
        if (nextDates.length > 0) {
          // nextDates: array of sessions that starts later than at least 15 mins
          console.log("later than 15 mins batches", nextDates);

          // find the index of the first session in sessions that is the first in nextDates
          const index = sessions.findIndex(
            (sessions: Session) => sessions.startTime === nextDates[0].startTime
          );

          const previousIndex = index - 1;
          //eslint-disable-next-line @typescript-eslint/no-unused-expressions
          if (
            index === 0 ||
            (index > 0 && sessions[previousIndex].status === "completed")
          ) {
            console.log(
              "last round completed or the first round will start after at least 15 mins"
            );
            const arr = sessions[index].batch.split(" ");
            const date = moment(arr[0]).format("DD-MM-YYYY");
            let time;
            if (arr[1].includes(":")) {
              time = arr[1];
            } else {
              time = `${Number(arr[1])}:00`;
            }
            const newBatch = `${time}${arr[2]} ${date}`;
            setRoundMessage(
              `Board round information not yet available.  Next scheduled round is ${newBatch}`
            );
            setLoading(false);
          } else {
            const session = sessions[previousIndex];
            console.log(
              "last round not completed, show the incomplete round",
              session
            );
            getPatients(
              token,
              session.batch,
              session.client,
              setLoading,
              setPatients,
              setClients,
              dispatch,
              setReduxTempRound,
              setReduxNoListRound,
              setRoundMessage,
              setReduxTempPatients,
              setAddPatients,
              setReduxFilteredPatients
            );
          }
        } else {
          if (sessions[sessions.length - 1].status === "completed") {
            console.log("rounds completed today");
            setRoundMessage("Board round information not yet available");
            setLoading(false);
          } else {
            console.log(
              "show last incompleted round,last one",
              sessions[sessions.length - 1]
            );
            const session = sessions[sessions.length - 1];
            getPatients(
              token,
              session.batch,
              session.client,
              setLoading,
              setPatients,
              setClients,
              dispatch,
              setReduxTempRound,
              setReduxNoListRound,
              setRoundMessage,
              setReduxTempPatients,
              setAddPatients,
              setReduxFilteredPatients
            );
          }
        }
      } else {
        //no sessions
        setRoundMessage("Board round information not yet available");
        setLoading(false);
      }
    })
    .catch((err) => {
      console.log("err getSessions", err);
      setLoading(false);
      setRoundMessage("No data available for this round");
    });
};

export const updateDiscussedPatient = ({
  roundInfo,
  setDialogLoading,
  patientInfo,
  actualFacem,
}: {
  roundInfo: Round;
  setDialogLoading: (loading: boolean) => void;
  patientInfo: IPatientInfo;
  actualFacem: string | null;
}) => {
  setDialogLoading(true);
  const arr = [
    { ...patientInfo, Doctor: patientInfo?.Doctor?.join(" ~ ") ?? "" },
  ];
  const data = Object.assign(
    {},
    {
      ...roundInfo,
      actualFacem,
      patients: [...arr],
      status: "in_progress",
    }
  );
  getTokenAzure()
    .then((token) => {
      return axios.post(
        "https://br-apim.azure-api.net/medaws/updatePatients",
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    })
    .catch((err) => {
      console.log("err", err);
    })
    .then((res) => {
      setDialogLoading(false);
    });
};
