import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import {
  setReduxValidationError,
  setReduxNoListRound,
  setReduxTempPatients,
  setReduxTempRound,
} from "../../redux/patient";
import { RootState } from "../../redux/rootReducer";
import { useAppDispatch } from "../../redux/index";
import { useStyles } from "./styles";
import axios from "axios";
import { getTokenAzure } from "../BoardRoundContainer/Function";
import moment from "moment";
import EndRoundBtnGroup from "../EndRoundBtnGroup/EndRoundBtnGroup";
import { EndRoundMessage } from "../../Data";
import Timer from "../Timer/Timer";

interface IHeadBarProps {
  patients: Array<IData>;
  setPatients: (patients: Array<IData>) => void;
  showPopup: (type: string, message: string, rn: number) => void;
  clients: Array<string>;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  roundMessage: string;
  open: boolean;
  userInfo: IUserInfo;
  selectedFacem: Facem | null;
  handleFACEMchange: (e: any, value: Facem | null) => void;
}

export default function HeadBar({
  setPatients,
  showPopup,
  clients,
  setLoading,
  loading,
  roundMessage,
  open,
  userInfo,
  selectedFacem,
  handleFACEMchange,
}: IHeadBarProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const tempPatients = useSelector(
    (state: RootState) => state.patient.tempPatients
  );
  const tempRound = useSelector((state: RootState) => state.patient.tempRound);
  const noListRound = useSelector(
    (state: RootState) => state.patient.noListRound
  );
  const facems = useSelector((state: RootState) => state.patient.facems);
  const validationError = useSelector(
    (state: RootState) => state.patient.validationError
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [selectedSubItem, setSelectedSubItem] =
    useState<string>("Cancelled by Site");
  const [comment, setComment] = useState<string>("");
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    option: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(option);
    if (selectedItem !== option) {
      setComment("");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const validateRequiredField = (option: string) => {
    let validated = true;
    let obj = {
      facemError: false,
      commentError: false,
      invalidPatientsRn: [] as number[],
    };

    // FACEM list not empty and FACEM not selected
    if (Object.keys(selectedFacem ?? {}).length === 0 && facems?.length) {
      validated = false;
      obj = Object.assign(
        {},
        {
          ...obj,
          facemError: true,
        }
      );
    }
    dispatch(setReduxValidationError(obj));
    return validated;
  };

  const updateRoundData = (option: string, message: string) => {
    setLoading(true);
    const roundInfo =
      Object.keys(tempRound).length > 0 ? tempRound : noListRound;
    const arr =
      tempPatients?.map((patient) => {
        return {
          ...patient,
          Doctor: patient?.Doctor?.join(" ~ ") ?? "",
        };
      }) ?? [];
    const endTime = moment().format("YYYY-MM-DD[T]HH:mm:ss");
    const data = Object.assign(
      {},
      {
        ...roundInfo,
        actualFacem: selectedFacem?.facemNameInTwilio || userInfo?.name,
        comments: comment,
        outcome: option,
        endTime: endTime,
        patients: [...arr],
        status: "completed",
      }
    );

    getTokenAzure()
      .then((token) => {
        return axios.post(
          "https://br-apim.azure-api.net/medaws/updatePatients",
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      })
      .catch((err) => {
        console.log("err", err);
      })
      .then((res) => {
        setLoading(false);
        setPatients([]);
        setComment("");
        dispatch(setReduxTempPatients([]));
        dispatch(setReduxTempRound({}));
        dispatch(setReduxNoListRound({}));
        window.localStorage.removeItem(
          `${tempRound.client} ${tempRound.batch}`
        );
        window.localStorage.removeItem(
          `${tempRound.client} ${tempRound.batch} timer`
        );
        showPopup("endRound", message, -1);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setComment("");
        showPopup("endRound", "Round submit failed!", -1);
      });
  };
  const submiteRound = () => {
    handleClose();
    //check comment, block, facem not empty in tempPatient
    let validated = false;
    validated = validateRequiredField(selectedItem);
    if (!validated) {
      showPopup(
        "endRound",
        selectedItem === "Round completed"
          ? EndRoundMessage.validationFailed
          : "Please select Facem",
        -1
      );
      return;
    }
    if (selectedItem === "Round completed") {
      updateRoundData(selectedItem, EndRoundMessage.roundCompleted);
    } else if (selectedItem === "Round interrupted") {
      if (comment) {
        updateRoundData(selectedItem, EndRoundMessage.roundInterrupted);
      } else {
        showPopup("endRound", EndRoundMessage.commentFailed, -1);
      }
    } else if (selectedItem === "Round cancelled") {
      if (comment) {
        updateRoundData(selectedSubItem, EndRoundMessage.roundCancelled);
      } else {
        showPopup("endRound", EndRoundMessage.commentFailed, -1);
      }
    }
  };

  const handleSubOptionClick = async (option: string) => {
    setSelectedSubItem(option);
  };
  const handleComentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComment(e.target.value);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  // const handleFACEMchange = (e: React.ChangeEvent<{}>, value: Facem | null) => {
  //   if (value !== null) {
  //     setSelectedFacem(value);
  //   }
  // };

  return (
    <AppBar position="static" color="default">
      <div className={classes.barContainer}>
        <Toolbar className={classes.toolBar}>
          <Button className={classes.tabOne}>{clients[0]}</Button>
          <Button
            onClick={() => showPopup("changeLoc", "hi", -1)}
            variant="contained"
            className={classes.locationButton}
            aria-controls="customized-menu"
            aria-haspopup="true"
          >
            Select / Change Location
          </Button>
        </Toolbar>
        <Timer loading={loading} roundMessage={roundMessage} open={open} />
        <div className={classes.facemContainer}>
          <Autocomplete
            id="FACEM"
            options={facems}
            value={selectedFacem}
            getOptionLabel={(option) => option.facemNameInTwilio}
            onChange={(e, value) => handleFACEMchange(e, value)}
            style={{ width: 300 }}
            classes={{
              root: validationError.facemError ? classes.outlinedInput : "",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="FACEM:"
                variant="outlined"
                size="small"
              />
            )}
          />
        </div>
        <EndRoundBtnGroup
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          selectedItem={selectedItem}
          selectedSubItem={selectedSubItem}
          handleSubOptionClick={handleSubOptionClick}
          handleComentChange={handleComentChange}
          submiteRound={submiteRound}
          comment={comment}
        />
      </div>
    </AppBar>
  );
}
